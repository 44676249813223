<template>
  <div
    v-editable="blok"
    class="aspect-square w-[calc(100%+2.5rem)] -translate-x-5 transform overflow-hidden bg-gray-200 lg:aspect-[800/540] lg:w-auto lg:transform-none"
  >
    <GoogleMap v-if="displayMap" :options="googleMapOptions" />
  </div>
</template>

<script setup lang="ts">
import type { BlockMapStoryblok } from '@/types/storyblok'
import type { CreateMapOptions } from '@/composables/useGoogleMap'

const props = defineProps<{ blok: BlockMapStoryblok }>()
const { communityContent } = storeToRefs(useCurrentStoryStore())
const displayMap = computed(() => {
  return (
    !!communityContent.value?.mapLocationLatitude &&
    !!communityContent.value?.mapLocationLongitude
  )
})
const googleMapOptions = computed(() => {
  const options: Omit<CreateMapOptions, 'htmlElement'> = {
    position: {
      lat: parseFloat(communityContent.value?.mapLocationLatitude as string),
      lng: parseFloat(communityContent.value?.mapLocationLongitude as string),
    },
    zoom: parseInt(props.blok.zoomLevel),
    markers: [
      {
        position: {
          lat: parseFloat(
            communityContent.value?.mapLocationLatitude as string,
          ),
          lng: parseFloat(
            communityContent.value?.mapLocationLongitude as string,
          ),
        },
        communityDetails: {
          color: communityContent.value?.backgroundDark.color,
          logo: communityContent.value?.logoDark,
        },
      },
    ],
  }

  return options
})
</script>
